/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/
@import "sass-lib";

body {  
    color: #000;
    font-size: 14px;
    font-family: $f_helve_md;
    position: relative;
    overflow-x: hidden;
    -webkit-text-size-adjust: 100%;
}
figure{
    margin: 0; padding: 0;
    img{vertical-align: middle;}
}
img{ border:0; max-width:100%; height:auto; }
p {
    margin: 1px 0 15px;
    line-height: 20px;
}
input, textarea, select {
    font-size: 12px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button  {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    opacity: 0;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
h1 { font-size: 32px; }
h2 { font-size: 28px; }
h3 { font-size: 24px; }
h4 { font-size: 20px; } 
h5 { font-size: 16px; } 
h6 { font-size: 14px; }


/* structure 
----------------------------------------------------------------------------------------------*/
.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.flex-list {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-flex-flow: row wrap;}

section { position: relative; }
.wrapper {width: 1200px;margin: 0 auto; position: relative;}

a.link_detail {
    display: inline-block; font-size: 16px; font-family: $f_helve_lt; color: $c_blue; text-decoration: underline; position: relative;
    padding-right: 20px;
    &:after {content:''; display: block; width: 0; height: 0; border-left: 6px solid $c_blue; @include transition(all ease-out 0.25s); border-top: 4px solid transparent; border-bottom: 4px solid transparent; position: absolute; right: 6px; top: 2px; bottom: 0; margin: auto;}
    &:hover {
        &:after {right: 2px; @include transition(all ease-out 0.25s);}
    }
}

/* header
----------------------------------------------------------------------------------------------*/
header {
    height: 70px; position: fixed; background: url('../images/material/header_bg.jpg') no-repeat; left: 0; right: 0; display: block; z-index: 9;
    .logo {position: absolute;padding-top: 20px; left: 0; display: block;}
    .rh_mob {float: right;}
    .lang {
        position: relative; padding-left: 15px; border-left: 1px solid rgba(255,255,255,0.3);
        span {
            font-size: 16px; line-height: 20px; color: #fff;padding: 25px 20px 25px 0px; display: block; position: relative; cursor: pointer;
            &:after {
                 @include transition(all ease-out 0.25s);width:0; height: 0; content: ''; border-left: 4px solid transparent; border-right: 4px solid transparent; border-top: 5px solid #fff; position: absolute; right: 2px; top: 0; bottom: 0; margin: auto;
            }
            &.act {
                &:after {@include transform(rotate(180deg)); @include transition(all ease-out 0.25s);}
            }
        }
        .opt_lang {
            display: none; position: absolute; padding: 10px 15px; background: #b4161c; left: 0; right: auto; width: 25px;
            a {padding: 5px 0; font-size: 14px; color: #fff; display: block;}
        }
    }
    .nav_menu {
        position: relative; float:  right;
        ul {
            @extend .after_clear;
            li {
                float: left;position: relative;
                a {padding: 25px 17px;font-size: 18px; color: #fff; text-transform: uppercase; font-family: $f_bebas; font-weight: 700; letter-spacing: 1px; line-height: 20px; display: block; position: relative;}
                &:first-child {margin-left: 0;}
                ul {
                    background: #b4161c; position: absolute; top: 100%;width: 180px; padding: 10px 20px 15px; left: -1px;@include boxSizing(border-box); display: none;
                    li {
                        display: block; float:  none; margin-left: 0;
                        a {
                            padding: 10px 0;font-size: 16px; position: relative;
                            &:before {content: ""; background: #fff; position: absolute; left: 0; right: auto; height: 2px; background: #fff; display: block; @include transition(all ease-out 0.25s); bottom: 2px; top: auto; width: 0;}
                            &:hover {
                                &:before { @include transition(all ease-out 0.25s); width: 30px; height: 2px;}
                            }
                        }
                    }
                }
                &:hover {
                    > a {
                        &:before {height: 100%; @include transition(all ease-out 0.25s);}
                    }
                }
            }
        }
        > ul > li > a {
            &:before {content:""; position: absolute; left: 0; right: 0; top: auto; bottom: 0;width: 100%; height: 0; background: #b4161c; z-index: -1; border-bottom: 2px solid #e1232f;}
        }
    }
}


/* middle
----------------------------------------------------------------------------------------------*/
.middle {
    padding-top: 70px;
}

.product_home {
    position: relative; padding-bottom: 85px; @include boxShadow(0 0 30px -6px rgba(0,0,0,0.20));
}
.txt_top {
    width: 495px; margin: 0 auto 30px; display: block; text-align: center;
    h6 {font-size: 14px; text-transform: uppercase; font-family: $f_helve_lt; letter-spacing: 10px; margin-bottom: 15px;}
    h2 {font-size: 46px; font-family: $f_helve_bold;margin-bottom: 20px; text-transform: uppercase;}
    p {font-family: $f_helve_lt; font-size: 16px; line-height: 20px; margin-bottom: 16px;}
    &.bintang {
        margin-bottom: 75px;
    }
}

.list3box {
    @extend .flex-list; justify-content: center; position: relative; top: -75px;
    .box {
        margin: 0 10px; position: relative;
        .txt_abs {
            position: absolute; top: 50%; @include transform(translateY(-50%)); padding: 0 20px; left: 0; right: 0;
            h3 {
                font-size: 28px; text-transform: uppercase; color: #fff; text-align: center; line-height: 32px; display: block;
                span {display: block; text-align: center;}
            }
        }
        &.grey {
            .txt_abs {
                h3 {color: #828284;}
            }
        }
    }
}

.SlideProd {
    background: url('../images/material/bg_prod2.png') no-repeat  center 180px;
    .SlideImg {
        .img {
            position: relative;
            > img {@include transform(scale(0.55)); position: relative; display: block;@include transition(all ease-out 0.35s); position: relative; z-index: 4;}
            &.slick-center {
                > img {@include transform(scale(1));@include transition(all .3s ease-out .2s); animation-delay: 0.2s;}
                .anim {
                    img {
                        &.anim_img1 {
                            @include transition(all .3s ease-out .5s); opacity: 1; @include transform(scale(1));
                            &.redl {left: 0px;}
                        }
                        &.anim_img2 {
                            z-index: 5; opacity: 1;@include transition(all .3s ease-out .7s);
                            &.zer {z-index: 3; opacity: 1;@include transition(all .3s ease-out .7s); left: 0;}
                            &.redl {@include transition(all .3s ease-out .7s);}
                        }
                        &.anim_img3 {
                            @include transition(all .3s ease-out .9s); @include transform(scale(1)); opacity: 1;
                            &.zer {
                                opacity: 1;@include transition(all .3s ease-out .7s);
                            }
                        }
                    }
                }
            }
            .anim {
                position: absolute; left: 0; right: 0; top: 0; bottom: 0; width: 100%; height: 100%;
                img {
                    position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; opacity: 0;
                    &.anim_img1 {
                        @include transition(all .3s ease-out); @include transform(scale(0.4));
                        &.zer {@include transform(scale(1)); z-index: 5;}
                        &.redl {z-index: 5;@include transform(scale(0.98)); left: 20px;}
                    }
                    &.anim_img2 {
                        z-index: 5;
                        &.zer {z-index: 3;left: 20px;}
                    }
                    &.anim_img3 {
                         @include transition(all .3s ease-out); @include transform(scale(0.5));
                          
                    }
                }
            }
        }
    }
    .slideKet_img {
        width: 400px; display: block; margin: 0 auto; text-align: center; margin-top: 10px;
        .dec {
            h3 {font-family: $f_helve_bold; font-size: 30px; margin-bottom: 5px;}
            p {font-size: 14px; color: #e1222e;}
        }
        .slick-arrow {
            width: 16px; height: 26px; display: block; position: absolute; z-index: 5; right: -25%; top: 0; bottom: 0; margin: auto;left: auto; background: url('../images/material/arr_slide.png') no-repeat; border: none; font-size: 0;
            cursor: pointer;
            &.slick-prev {
                right: auto; left: -25%; @include transform(rotate(180deg));
            }
        }
    }
}
.banner {
    figure {
        img {display: block;}
    }
}

.home_article {
    position: relative; padding: 60px 0;
}
.listing {
    @extend .flex-list;
    .item {position: relative;}
    &.col3 {
        .item {
            margin-right: 1.25%; width: 32.5%;
            &:nth-child(3n) {margin-right: 0;}
            .img {
                img {width: 100%;}
            }
        }
    }
    
}

.home_article {
    .listing {
        .item {
            position: relative;
            .img {
                position: relative; overflow: hidden;
                img {display: block;@include transition(all ease-out 0.3s);}
                &:after {@include transition(all ease-out 0.25s); content:''; display: block; left: 0; right: 0; top: 0; bottom: 0; position: absolute; background: rgba(60,60,60,0.7);}
            }
            h5 {
                letter-spacing: 1px; position: absolute; left: 0; right: 0; top: 50%; display: block; padding: 0 30px; text-align: center; font-size: 42px; font-family: $f_helve_bold;text-transform: uppercase; color: #fff; padding-bottom: 10px;
                &:after {content:''; left: 0; right: 0; bottom: 0;  width: 0px; background: #e01b22; display: block; position: absolute; height: 3px; margin: auto;@include transition(all ease-out 0.25s);}
            }
            &:hover {
                .img {
                    img {@include transition(all ease-out 0.5s); @include transform(scale(1.1));}
                    &:after {opacity: 0;@include transition(all ease-out 0.25s);}
                }
                h5 {
                    &:after {@include transition(all ease-out 0.25s); width: 50px;}
                }
            }
        }
    }
}
.top_back {
    margin-bottom: 40px;
    h6 {font-size: 15px; color: #616161; font-family: $f_helve_lt; float: left;}
    a.right {
        color: #e1232f;  font-family: $f_helve_lt; font-size: 15px; padding-left: 20px; position: relative;
        &:before {content:''; display: block; width: 0; height: 0; border-right: 6px solid #e1232f; @include transition(all ease-out 0.25s); border-top: 4px solid transparent; border-bottom: 4px solid transparent; position: absolute; left: 5px; top: 0px; bottom: 0; margin: auto; right: auto;}
        &:hover {
            &:before {
                left: 2px; @include transition(all ease-out 0.25s);
            }
        }
    }
    &.float {
        position: absolute; left: 0; right: 0; top: 0; z-index: 2; margin-top: 90px;
    }
    .navSlider {
        .nav {
            display: inline-block; padding: 0 20px;border-left: 1px solid #363636; cursor: pointer;
            h5 {font-size: 18px; line-height: 20px;}
            &:last-child {
                padding-right: 0;
            }
            &:first-child {
                padding-left: 0; border-left: none;
            }
            &.slick-current {
                h5 {color: #e41e26;}
            }
        }
    }
    .breadcrumb {
        float: left;
        ul {
            li{
                display: inline; padding-right: 10px; margin-right: 10px; position: relative;
                a {
                    color: #363636; font-size: 18px; line-height: 20px; @include transition(all ease-out 0.25s);
                    &:hover,
                    &.active {color:#e41e26; @include transition(all ease-out 0.25s);}
                }
                &:after {
                    content:">"; font-size: 18px; color: #363636; position: absolute; right: -8px;
                }
                &:last-child {
                    padding-right: 0;
                    &:after {display: none;}
                }
            }
        }
    }
}

.det_video{
    video{ background: #000; }
}
.sec_experience {
    margin-bottom: 60px; padding-top: 30px;
    .txt_top {
        width: 700px; margin-bottom: 40px;
        p {font-size: 13px; line-height: 18px;}
    }
}

.wrap_pngalaman,
.wrap_campaign {
    padding: 100px 0 0;
}
.wrap_sec {
    width: 990px; margin: 0 auto; @extend .after_clear;
    .lf_wrap {
        float: left; width: 47%;
        .vid {
            iframe {height: 380px;}
        }
    }
    .rh_wrap {
        float: right; width: 47%;
        .text {
            p {font-size: 16px; font-family: $f_helve_lt; margin-bottom: 20px;}
        }
    }
}
.slideLabel {
    width: 720px; margin: auto;
    .slideImg_logo {
        width: 670px; margin: auto;
        .logoBintang {width: 670px;}
        .flickity-button {
            width: 16px; height: 26px; display: block; position: absolute; z-index: 5; right: -30px; top: 0; bottom: 0; margin: auto;left: auto; background: url('../images/material/arr_slide.png') no-repeat; border: none; font-size: 0;
            cursor: pointer;
            svg {display: none;}
            &.previous {
                right: auto; left: -30px; top: -25px; @include transform(rotate(180deg));
            }
        }
    }
    .dotSlide {
        margin-top: 20px;
        .dots {
            display: inline-block; width: 80px;
            .bullet {
                width: 80px; height: 25px; display: block; position: relative; z-index: 5; margin-bottom: 5px;
                &:after {content: ''; height: 8px; background: #e41e26; width: 8px; position: absolute; display: block;left: 0; right: 0; top: 4px; margin: auto; @include borderRadius(50%);}
                &:before {content: ''; width: 8px; height: 8px; display: block; top: 3px; position: absolute; left: 0; right: 0; margin: auto; border: 1px solid #e41e26; @include borderRadius(50%); background: #fff; opacity: 0; @include transition(all ease-out 0.3s);}
            }
            h6 {font-size: 13.7px; font-family: $f_helve_md; text-align: center; margin: 0 -5px; line-height: 15px; display: block; padding-bottom: 5px;}
            &:after {content: ''; height: 1px; background: #e41e26; width: 50%; position: absolute; display: block;left: auto; right: -1px; top: 8px;}
            &:before {content: ''; height: 1px; background: #e41e26; width: 50%; position: absolute; display: block;left: -1px; right: auto; top: 8px;}
            
            &:first-child {
                &:before {display: none;}
            }
            &:last-child {
                &:after {display: none;}
            }
            &.is-selected {
                .bullet {
                    &:before {@include transition(all ease-out 0.3s); width: 14px; height: 14px; top: 0; opacity: 1;}
                }
            }
        }
        .flickity-button {display: none;}
    }
}

.wrap_product {position: relative;}
.SlideProd {
    .pg_slider {background-size: cover !important; padding: 185px 0 40px;}
        .wrap_slide {width: 1280px; margin: 0 auto;} 
        .logo_prod {
            text-align: center; margin-bottom: 40px;
            img {display: block; margin: auto; width: 385px;}
        }
        .content_slide {
            @extend .after_clear;
            .box_dec {
                width:62%; float: right; background: rgba(255,255,255,0.7); padding: 30px 25px; @include boxSizing(border-box);
                h6 {font-weight: 700;}
                .bx_grey {
                    .col {
                        h6 {margin-bottom: 20px;}
                    }
                }
            }
            .img {float: left; width: 35%;}
            .bx_grey, .bx {
                background: rgba(204,204,204,0.3); padding: 10px 15px;
                h6 {margin-bottom: 8px;}
                p {margin-bottom: 10px;}
                ul.col {
                    @extend .flex-list;
                    li {width: 50%; padding-right: 30px; @include boxSizing(border-box);}
                }
            }
            .bx {background: transparent;}
        }
       .slick-arrow {
            width: 19px; height: 33px; display: block; position: fixed; z-index: 5; right: 40px; top: 100px; bottom: 0; margin: auto;left: auto; background: url('../images/material/arr_slide_2.png') no-repeat; border: none; font-size: 0;
            cursor: pointer;
            &.slick-prev {
                right: auto; left: 40px; @include transform(rotate(180deg));
            }
            &.slick-disabled {opacity: 0.45;}
        }
}

.wrap_campaign {
    .top_back {
        margin-bottom: 20px;
        .breadcrumb ul li {
            a {
                color: #fff;
                &:hover,
                &.active {color: #e41e26;}
            }
            &:after {color: #fff;}
        }
    }
}
.wrap_campaign {
    padding-bottom: 30px;
    .wrapper {width: 1310px; margin: 0 auto;}
    &.detail {
        padding: 40px 0 70px;
        .top_back {margin-bottom: 50px;}
    }
}

.content_campaign {
    
}
.list_campaign {
    @extend .flex-list; justify-content: center;
    .box {
        width: 31.45%; margin-right: 2.825%; margin-bottom: 80px;
        &:nth-child(3n) {margin-right: 0;}
        a {color: #000;}
        .img {
            img {display: block;}
        }
        .dec {
            background: rgba(255,255,255,0.8); padding: 15px 40px 40px; position: relative; margin: -40px 20px; @include boxSizing(border-box);
            h3 {font-size: 18px; line-height: 22px; text-align: center; margin-bottom: 15px;}
            .tgl {color: #e41e26; font-size: 18px;}
        }
    }
}
.hdline_campaign {
    .big {
        width: 100%; margin-right: 0;  margin-bottom: 40px;
        > a {@extend .flex-list; color: #000;}
        .img {width: 42.5%;}
        .dec {
            width: 57.5%; padding: 50px 110px 50px 50px;  margin: 0;background: rgba(255,255,255,0.8); @include boxSizing(border-box);
            h3 {text-align: left; font-size: 30px; font-weight: 700; line-height: 36px; margin-bottom: 15px;}
            p {font-size: 18px; font-family: $f_helve_lt; line-height: 26px;}
        }
        .v-center {top: 50%; @include transform(translateY(-50%)); position: relative;}
    }
}

.wrp_detail {
    width: 1030px; margin: auto;
    .txt_top {
        width: 730px;
        h2 {margin-bottom: 30px;}
        p {text-align: left;}
    }
    .list_campaign {
        .box {
            .dec {
                background: rgba(0,0,0,0.75); padding: 15px 20px;
                h3 {margin-bottom: 0; color: #fff; font-size: 16px;}
            }
        }
    }
}

.wrap_find {padding: 100px 0 40px;}
.sec_find  {
    width: 1000px; margin: auto auto 100px;
    >h3 {font-size: 32px; font-family: $f_helve_bold; text-transform: uppercase; text-align: center; margin-bottom: 35px;}
}
.list_find {
    @extend .flex-list; justify-content: center;
    .item {margin: 0 15px 15px;}
}
.wrap_event {
    padding: 100px 0; background-size: cover !important;
}

.main_article {
    .filter {
        @extend .after_clear; margin-bottom: 30px;
        .col {
            float: left; margin-right: 30px;
            .select_cus {
                border: 1px solid #e1232f;position: relative;
                select {background: url('../images/material/arr_select.png') no-repeat 93% center; padding-right: 35px;}
                &:after {
                    content: ''; width: 1px; background: #e1232f; height: 100%; position: absolute; display: block; right: 35px; top: 0;
                }
            }
            label {font-size: 16px; color: #636363; display: inline-block; margin-right: 10px;}
            select {
                display: inline-block; font-size: 18px; font-family: $f_helve_md; color: #000; border: none; min-width: 170px; padding-right: 33px;
                background: url('../images/material/arr_select2.png') no-repeat 93% center; cursor: pointer; padding-left: 15px;
            }
        }
    }
    h3.title {font-size: 24px; padding-bottom: 15px; margin-bottom: 50px; border-bottom: 2px solid #e1232f;}
}
.main_event {
    @extend .after_clear;
    .main_article {float: left; width: 65%;}
    .asside_article {
        float: right;width: 32%; margin-top: 115px;
        .boxs {
            padding: 60px 30px; background: url('../images/material/bg-find-us.jpg') no-repeat; background-size: cover;
            h2 {font-size: 45px;text-transform: uppercase; font-family: $f_helve_bold; line-height: 50px; margin-bottom: 20px; color: #241d1e;}
        }
    }
}
.top_detail {
    .categ {display: inline-block; padding-right: 10px; margin-right: 8px; border-right: 1px solid #e1232f;}
    .info_tgl {display: inline-block;}
}

.info_tgl {font-size: 14px; color:#241e1e; font-family: $f_helve_lt; margin-bottom: 10px;}

.list_article {
    border-bottom: 3px solid #e1232f;
    .item {
        @extend .flex-list; justify-content: space-between; padding-bottom: 30px; margin-bottom: 30px; border-bottom: 1px solid #e1232f;
        .img {width: 47.5%;}
        .det {
            width: 48%;
            .info_tgl {font-size: 14px; color:#241e1e; font-family: $f_helve_lt; margin-bottom: 10px;}
            h3 {
                font-size: 30px; font-weight: 700; line-height: 36px; margin-bottom: 20px;
                a {color: #000; text-transform: uppercase; font-family: $f_helve_bold;}
            }
            p {font-family: $f_helve_lt; font-size: 16px; line-height: 24px;}
        }
        &:last-child {
            border-bottom: 0;
        }
    }
}
.pagging {
    margin-top: 30px;
    ul {
        li {
            display: inline-block;
            a {
                @include transition(all ease-out 0.25s);font-size: 18px; width: 36px; height: 36px; line-height: 36px; display:block;text-align: center; font-family: $f_helve_bold; color: #000; position: relative; margin: 0 3px;
                &:before {content:''; background: #e1232f; left: 0; right: 0; top: 0; bottom: 0;  @include transition(all ease-out 0.25s); position: absolute; width: 10px; height: 10px; margin: auto; z-index: 1; opacity: 0;}
                &:hover, &.active {
                    color: #fff; @include transition(all ease-out 0.25s);
                    &:before {width: 100%; height: 100%; @include transition(all ease-out 0.25s); opacity: 1;}
                }
                span {position: relative; z-index: 2;}
            }
            &.prev,
            &.next {
                a {
                    font-size: 17px;
                    &:before {display: none;}
                    &:hover {
                        color: #e1232f;
                        i {}
                    }
                }
            }
            &.prev {
                a {
                    margin-right: 20px; padding-left: 20px;
                    i {
                        display: block;width: 0;height: 0;border-right: 8px solid #000;border-top: 5px solid transparent;border-bottom: 5px solid transparent;position: absolute;right: auto;top: 2px;bottom: 0; margin: auto; left: 0;
                    }
                    &:hover {
                        i {border-right-color: #e1232f;}
                    }
                }
            }
            &.next {
                a {
                    margin-left: 20px; padding-right: 25px;
                    i {
                        display: block;width: 0;height: 0;border-left: 8px solid #000;border-top: 5px solid transparent;border-bottom: 5px solid transparent;position: absolute;right: 0px;top: 2px;bottom: 0; margin: auto;
                    }
                    &:hover {
                        i {border-left-color: #e1232f;}
                    }
                }
            }
        }
    }
}

.text_detail {
    font-size: 16px; line-height: 24px; font-family: $f_helve_lt; padding-top: 10px;
    h1 {font-size: 45px; font-family: $f_helve_bold; line-height: 48px; text-transform: uppercase; margin-bottom: 40px;}
    .images {
        margin: 30px 0;
        img {display: block;}
    }
    p {margin-bottom: 20px;}
    ol {
        padding-left: 15px;list-style: decimal;
        li {margin-bottom: 10px;}
    }
    ul {
        padding-left: 15px;list-style: disc;
        li {margin-bottom: 10px;}
    }
    .short {
        position: relative; margin: 30px 0;
        p {font-size: 18px; font-family: $f_helve_md; color: #555; line-height: 24px; position: relative; z-index: 2;}
        &:before {content: ""; width: 75px; height: 60px; background: url('../images/material/quote.jpg') no-repeat; display: block; position: absolute; left: -40px; top: -10px; z-index: 1;}
    }

}

.related {
    h3 {font-size: 26px; text-transform: uppercase; font-family: $f_helve_bold; padding-bottom: 15px; margin-bottom: 20px; border-bottom: 1px solid #e1232f;}
    .list_related {
        .item {
            padding-bottom: 15px; margin-bottom: 25px; border-bottom: 1px solid #eaeaea;
            h4 {
                font-size: 18px; color: #363636; margin-bottom: 10px; line-height: 22px;
                a {
                    color: #363636; text-transform: uppercase;
                    &:hover {color: #e1232f;}
                }
            }
        }
    }
}

.btm_detail {
    padding-top: 20px; margin-top: 50px; position: relative;
    &:before {content: ''; height: 1px; background: #e1232f; display: block; position: absolute; top: 0; left: 0; width: 50px;}
    .tag {
        margin-bottom: 40px;
        label {display: inline-block; font-size: 14px;color: #555; margin-right: 10px; vertical-align: middle;}
        ul {
            display: inline-block; vertical-align: middle;
            li {
                display: inline-block; vertical-align: middle; margin: 0 3px;color: #555;position: relative;
                &:after {
                    content:','; font-size: 14px; color: #555; display: inline-block; margin-left: 5px;
                }
                a {
                    color: #555;
                    &:hover {color: #e1232f;}
                }
                &:last-child {
                    &:after {display: none;}
                }
            }
        }
    }
    .share {
        label {display: inline-block; font-size: 16px; text-transform: uppercase; color: #555; margin-right: 10px;}
        ul {
            display: inline-block; vertical-align: middle;
            li {
                display: inline-block; vertical-align: middle; margin: 0 3px; position: relative;
                a {
                    width: 32px; display: block; height: 32px; opacity: 0.8;
                    &:hover {opacity: 1;}
                }
            }
        }
    }
}


.page_screen_age {
    height: 100vh; min-height: 600px; background-size: cover !important; /*padding: 60px 0;*/ @include boxSizing(border-box);
    .content_pg_Age {
        width: 900px; margin: 0 auto; text-align: center; @include transform(translateY(-50%)); top: 46%; position: relative;
        .logo_bir {width: 105px; margin: 0 auto 30px; img {width: 100%;}}
        .nav_lang {
            margin-bottom: 40px; display: inline-block; padding: 0 20px 0 40px; background: url('../images/material/lang.png') no-repeat left center; background-size: 22px;
            a {
                font-size: 18px; font-family: $f_helve_bold; color: #757575; line-height: 22px; padding-right: 10px; border-right: 1px solid #555; margin-right: 7px;
                &:last-child {border-right: none;}
                &.active, &:hover {
                    color: #e1222e;
                }
            }
        }
    }
    .drop_content {
        display: none;
        h6 {font-size: 24px; font-family: $f_helve_lt; text-transform: uppercase; letter-spacing: 5px; margin-bottom: 20px;}
        h2 {font-size: 50px; font-family: $f_helve_bold; color: #000; text-transform: uppercase; margin-bottom: 40px;}
        form {
            .row {
                @extend .flex-list; justify-content: center; font-family: $f_bebas; margin-bottom: 40px;
                .col {
                    width: 85px; margin: 0 30px;
                    .inp {
                        position: relative;
                        input {
                            font-size: 70px; font-family: $f_bebas; padding: 5px 0; text-align: center; width: 100%; font-weight: 700; background: transparent; border: none; @include boxSizing(border-box); text-align: center; letter-spacing: 2px; position: relative; z-index: 2;
                            color: #000;
                            &:focus {
                                color: #fff;
                                + i {height: 100%;@include transition(all ease-out 0.3s);}
                            }
                        }
                        i {position: absolute; left: 0; right: 0; top: auto; bottom: 0; height: 2px; background: #e1222e; width: 100%; @include transition(all ease-out 0s); z-index: 1; display: block;}
                    }
                    label {font-size: 20px; font-family: $f_helve_lt; text-transform: uppercase; display: block; margin-top: 15px;}
                    &:nth-child(3) {width: 150px;}
                }
            }
        }
        .txt {
            margin-bottom: 40px;
            p {
                font-size: 16px; font-family: $f_helve_lt; font-style: italic; line-height: 26px;
                a {color: #e1222e;}
            }
        }
        .link {
            text-align: center;
            > span {display: inline-block; font-size: 16px; color: #252525; font-style: italic; text-transform: uppercase; margin: 0 12px; font-family: $f_helve_lt;}
            .btn {
                position: relative;display: inline-block; padding: 0 30px; min-width: 280px; @include boxSizing(border-box); border: 2px solid #e1222e; line-height: 50px; font-size: 18px; text-transform: uppercase; text-align: center; background: transparent; color: #252525; cursor: pointer;
                span {position: relative; z-index: 2; letter-spacing: 1px;}
                &.fb {
                    color: #4561a2; border-color: #4561a2;
                    &:before {background: #4561a2;}
                }
                &:before {
                    content: ''; left: 0; right: 0; bottom: 0; top: 0; margin: auto; background: #e1222e; position: absolute; display: block; opacity: 0; @include transform( scale(0.8)); @include transition(all ease-out 0.2s);
                }
                &:hover {
                    color: #fff;
                    &:before {
                        opacity: 1; @include transform(scale(1));@include transition(all ease-out 0.2s);
                    }
                }

            }
        }
    }
}


.content-pages {   
  ol,ul {
    margin-bottom: 24px;
  } 
  li {
    margin-bottom: 12px;
  }
  ol {
    counter-reset: counter-list;
    > li {
      position: relative;
      padding-left: 20px;
      counter-increment: counter-list;
      &::before {
        content: counter(counter-list) ". ";
        position: absolute;
        top: 0;
        left: 0;
      }
      ol {
        counter-reset: counter-list-child;
        > li {
          counter-increment: counter-list-child;
          &::before {
            content: counter(counter-list) "." counter(counter-list-child);
          }
          ol {
            counter-reset: counter-list-subchild;
            > li {
              counter-increment: counter-list-subchild;
              padding-left: 50px;
              &::before {
                content: counter(counter-list) "." counter(counter-list-child)
                  "." counter(counter-list-subchild);
              }
            }
          }
        }
      }
    }
  }

  ul {
    > li {
      position: relative;
      padding-left: 20px;
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 8px;
        left: 2px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #DCDCDC;
      }      
    }
    &.small-list {
      li {
        &::before {
          width: 5px;
          height: 5px;
          top: 8px;
        }
      }
    }
  }
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all ease-out 0.2s;
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);    
  }
  &__box {
    width: 100%;
    max-width: 628px;
    background: rgba(0,0,0,.7); 
    border-radius: 0;
    box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 2;
    padding: 40px;
    transition: all ease-out 0.3s;
    transform: scale(0.9);
    opacity: 0;
    color: #DCDCDC;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5; 
    h3 {
      margin-bottom: 16px; 
    }
    p {
      line-height: 1.5; 
      margin-bottom: 20px;
    }
    a {
      color: #DCDCDC;
      text-decoration: underline;
      &:hover {
        text-decoration: none; 
      }
    }
    .goto {
      display: inline-block;
      padding: 12px 24px;
      border: 1px solid rgba(255,255,255, .3); 
      color: #fff;
      font-weight: 600; 
      text-decoration: none;

    }
    &--large {
      max-width: 960px;
    }
  }
  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    transform: scale(1);
    transition: 0.2s all ease-out;
    z-index: 5; 
    background: url('../images/material/close.svg') no-repeat 0 0;
    &:hover {
      transform: scale(0.9);
    }
  }
  &__content {
    &--center {
      text-align: center;
    }
    &--right {
      text-align: right;
    }
    figure {
      margin-bottom: 32px;
      img {
        width: 100%;
        max-height: 360px;
        object-fit: cover;
      }
    }
    .content-pages {
      i {
        font-style: italic;
        font-family: inherit !important; 
        font-weight: inherit; 
        font-size: large; 
      }
      li {
        margin-bottom: 4px; 
      }
    }
  }
  &__overflow {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 260px);
    padding-right: 40px;
    margin-right: -40px; 
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 0px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.7);
    }
    &::-webkit-scrollbar-thumb {
      background: #F01A28;
      border-radius: 0px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #F01A28;
    }
  }

  &__show {
    opacity: 1;
    visibility: visible;
    .popup {
      &__box {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}




/* footer
----------------------------------------------------------------------------------------------*/

footer {
    background: #000; text-align: center; padding: 15px 0;
    p {display: inline-block; color: #fff; font-size: 16px; margin: 7px 0 0;}
    .sos {
        float: right;
        ul {
            li {
                display: inline-block; margin-left: 10px;
                a {
                    display: block;@include transition(all ease-out 0.25s);
                    &:hover {@include transition(all ease-out 0.25s); opacity: 0.7;}
                }
            }
        }
    }
}